import React, { useState, useEffect } from "react";
import SearchBar from "../searchbar/searchBar";
import headerStyles from "./headerComponent.module.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Autocomplete from "@mui/material/Autocomplete";
import { matchSorter } from "match-sorter";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      background: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HeaderComponent = (props) => {
  const {getTranslation, lang} = props;
  const [showAdancedSearch, setShowAdancedSearch] = useState(false);
  const theme = useTheme();
  const [checked, setChecked] = React.useState([1]);
  const { stockStatistics } = props;

  const [selectColor, setSelectColor] = React.useState([]);
  const [selectSerie, setSelectSerie] = React.useState([]);
  const [sliderPriceValue, setSliderPriceValue] = React.useState();
  const [sliderStockValue, setSliderStockValue] = React.useState();
  const [inputColorValue, setInputColorValue] = React.useState("");
  const [inputSerieValue, setInputSerieValue] = React.useState("");

  

  useEffect(() => {
    if (stockStatistics && stockStatistics[0]) {
      setSliderPriceValue([0, stockStatistics[0].maxPriceExcl]);
      setSliderStockValue([0, stockStatistics[0].maxM2]);
    }
  }, [stockStatistics]);

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);

  const handleChangeSliderPrice = (event, newValue) => {
    setSliderPriceValue(newValue);
  };

  const handleChangeSliderStock = (event, newValue) => {
    setSliderStockValue(newValue);
  };

  return (
    <Grid pt={1} style={{ backgroundColor: theme.background.main, width: '100%' }}>
      <Grid m={2} style={{ backgroundColor: theme.background.main }}>

      </Grid>
      <Collapse
        in={!showAdancedSearch}
        timeout="auto"
        style={{ backgroundColor: theme.background.main }}
        unmountOnExit
      >
        <SearchBar
          stockStatistics={stockStatistics}
          onSearchChange={props.onSearchChange}
          getTranslation={getTranslation}
          lang={lang}
        />
      </Collapse>
{/*
      <Box style={{ backgroundColor: theme.background.main }}>
        <Box onClick={() => setShowAdancedSearch(!showAdancedSearch)}>
          <Typography
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
            sx={{ m: "0 0 0 15px" }}
            hidden={!showAdancedSearch}
            onClick={() => setShowAdancedSearch(!showAdancedSearch)}
          >
            Dölj avancerad sök
            <KeyboardArrowUpIcon />
          </Typography>
          <Typography
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
            sx={{ m: "0 0 0 15px" }}
            hidden={showAdancedSearch}
            onClick={() => setShowAdancedSearch(!showAdancedSearch)}
          >
            Visa avancerad sök <KeyboardArrowDownIcon />
          </Typography>
        </Box>
        <Collapse
          in={showAdancedSearch}
          timeout="auto"
          style={{ backgroundColor: theme.background.main }}
          unmountOnExit
          sx={{ p: "0 15px 0 15px" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  style={{ backgroundColor: theme.text.main }}
                  options={
                    stockStatistics &&
                    stockStatistics[0] &&
                    stockStatistics[0].alt_colors
                  }
                  //getOptionLabel={(option) => option}
                  filterOptions={filterOptions}
                  onChange={(event, value) => setSelectColor(value)}
                  inputValue={inputColorValue}
                  onInputChange={(event, value, reason) => {
                    console.log(selectColor);
                    if (event && event.type === "blur") {
                      setInputColorValue("");
                    } else if (reason !== "reset") {
                      setInputColorValue(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Alternativ färg"
                      placeholder=""
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        size="small"
                        {...getTagProps({ index })}
                        sx={{
                          backgroundColor: theme.background.main,
                          color: theme.text.main,
                        }}
                      />
                    ))
                  }
                ></Autocomplete>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  style={{ backgroundColor: theme.text.main }}
                  options={
                    stockStatistics &&
                    stockStatistics[0] &&
                    stockStatistics[0].series
                  }
                  //getOptionLabel={(option) => option}
                  filterOptions={filterOptions}
                  onChange={(event, value) => setSelectSerie(value)}
                  inputValue={inputSerieValue}
                  onInputChange={(event, value, reason) => {
                    console.log(selectSerie);
                    if (event && event.type === "blur") {
                      setInputSerieValue("");
                    } else if (reason !== "reset") {
                      setInputSerieValue(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Serie"
                      placeholder=""
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => {
                    const matches = match(option, inputValue);
                    const parts = parse(option, matches);

                    return (
                      <li {...props}>
                        <div>
                          {parts.map((part, index) => (
                            <span
                              key={index}
                              style={{
                                fontWeight: part.highlight ? 700 : 400,
                              }}
                            >
                              {part.text}
                            </span>
                          ))}
                        </div>
                      </li>
                    );
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        size="small"
                        {...getTagProps({ index })}
                        sx={{
                          backgroundColor: theme.background.main,
                          color: theme.text.main,
                        }}
                      />
                    ))
                  }
                ></Autocomplete>
              </FormControl>
            </Grid>



            <Grid item xs={12} lg={6}>
              
              <InputLabel
                id="multiple-chip-label-serie"
                sx={{ color: theme.text.main }}
              >
                Pris
              </InputLabel>
              <Slider
                sx={{ color: theme.text.main, width: "97%", mt:"15px", ml:"5px" }}
                getAriaLabel={() => "Temperature range"}
                value={sliderPriceValue}
                onChange={handleChangeSliderPrice}
                valueLabelDisplay="auto"
                step={50}
                max={
                  stockStatistics &&
                  stockStatistics[0] &&
                  stockStatistics[0].maxPriceExcl
                }
                //getAriaValueText={sliderPriceValue}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputLabel
                id="multiple-chip-label-serie"
                sx={{ color: theme.text.main }}
              >
                I lager
              </InputLabel>
              <Slider
                sx={{ color: theme.text.main, width: "97%", mt:"15px", ml:"5px" }}
                getAriaLabel={() => "Temperature range"}
                value={sliderStockValue}
                onChange={handleChangeSliderStock}
                valueLabelDisplay="auto"
                step={10}
                max={
                  stockStatistics &&
                  stockStatistics[0] &&
                  stockStatistics[0].maxM2
                }
                //getAriaValueText={sliderPriceValue}
              />
            </Grid>
          </Grid>
        </Collapse>
        
      </Box> 
      */}
    </Grid>
  );
};

export default HeaderComponent;
