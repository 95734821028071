import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import HeaderComponent from "../headercomponent/headerComponent";
import Table from "../table/tableComponent";
import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import translations from "../translations.json";

const cchTheme = createTheme({
  spacing: 8,
  background: {
    main: "#001C27",
    part: "#C8CCD0",
  },
  text: {
    main: "#FFFFFF",
    part: "#001C27",
  },
  logo: {
    url: "https://www.hoganaskakel.se/app/uploads/2021/05/hoganas-logo-PO-rgb-neg.png",
  },
});

const eversTheme = createTheme({
  spacing: 8,
  background: {
    main: "#001C27",
    part: "#C8CCD0",
  },
  text: {
    main: "#FFFFFF",
    part: "#001C27",
  },
  logo: {
    url: "https://www.hoganaskakel.se/app/uploads/sites/2/2021/05/evers-neg-po-e1620813474266.png",
  },
});

const theme = createTheme({
  spacing: 8,
  background: {
    main: "#ECEAE5",
    part: "#3F4B4E",
  },
  text: {
    main: "#000000",
    part: "#DDDDDD",
  },
  logo: {
    url: "https://www.colorama.se/Resources/Graphics/Logo.svg",
  },
});

export default function MainFrame() {
  const [articles, setArticles] = useState();
  const [stockStatistics, setStockStatistics] = useState();
  const [latLong, setLatLong] = useState({ lat: 1, long: 1 });
  const [brandTheme, setBrandTheme] = useState("CCH");
  const [lang, setLang] = useState("sv");
  const [currentTheme, setCurrentTheme] = useState(cchTheme);
  const [uniqueId, setUniqueId] = useState();

  const sortTableOn = (sortId, sub, sortDir) => {
    const temp = [...articles];
    const sortedParts = temp.sort(dynamicSort(sortId, sub, sortDir));
    setArticles(sortedParts);
  };

  function dynamicSort(property, sub, sortDir) {
    var sortOrder = sortDir;
    return function (a, b) {
      var result;
      if (sub) {
        result =
          a[sub][property] < b[sub][property]
            ? -1
            : a[sub][property] > b[sub][property]
              ? 1
              : 0;
      } else {
        result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      }
      return result * sortOrder;
    };
  }

  const onSearchChange = (event) => {
    if (event && event.length > 2) {
      searchForParts(event);
    }
  };

  const getTranslation = (field, lang) => {
    if (
      translations.filter((a) => a.field === field).length === 0 ||
      translations
        .filter((a) => a.field === field)[0]
        .translations.filter((b) => b.lang === lang).length === 0
    ) {
      return field;
    }
    return translations
      .filter((a) => a.field === field)[0]
      .translations.filter((b) => b.lang === lang)[0].text;
  };

  const searchForParts = (searchString) => {
    localStorage.setItem("part", searchString);
    searchString =
      "https://ifs-pricelist-api-sec.azurewebsites.net/findPartsById?id=" +
      searchString;
    fetch(searchString)
      .then((res) => res.json())
      .then((res) => {
        setArticles(res);
        console.log(res)
      });
    getUniqueId();
    logSearch(searchString);
  };

  const getStockStatistics = () => {
    fetch("https://ifs-pricelist-api-sec.azurewebsites.net/getStockStatistics")
      .then((res) => res.json())
      .then((res) => {
        setStockStatistics(res);
      });
  };

  const getUniqueId = async () => {
    let id = localStorage.getItem("uniqueId");
    if (id && id.length > 0) {
      setUniqueId(id);
      return { uniqueId: id };
    } else {
      return await fetch(
        "https://ifs-pricelist-api-sec.azurewebsites.net/generateUniqueId"
      ).then((res) => res.json());
    }
  };

  const logSearch = async (searchString) => {
    const fetchedUniqueId = await getUniqueId();
    localStorage.setItem("uniqueId", fetchedUniqueId.uniqueId);
    setUniqueId(fetchedUniqueId.uniqueId);

    const data = {
      latitude: latLong.lat,
      longitude: latLong.long,
      searchString: searchString,
      brand: brandTheme,
      uniqueId: fetchedUniqueId.uniqueId,
    };

    await fetch(
      "https://ifs-pricelist-api-sec.azurewebsites.net/logSearch",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then((response) => response.json());
  };

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setLatLong({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
    const params = new URLSearchParams(window.location.search);
    const part = params.get("part");

    let searchString = "basic";
    if (part && part.length > 0) {
      searchString = part;
    } else if (
      localStorage.getItem("part") &&
      localStorage.getItem("part").length > 0
    ) {
      searchString = localStorage.getItem("part");
    }
    const url = window.location.href;
    console.log(url)
    if (url.toLowerCase().includes("evers")) {
      setCurrentTheme(eversTheme);
      setLang("da");
    }

    window.history.pushState("", "Title", "/");

    const brand = params.get("brand");

    if (brand && brand.length > 0) {
      setBrandTheme(brand);
      localStorage.setItem("brand", brand);
    }
    searchForParts(searchString);
    getStockStatistics();
  }, []);

  return (
    <Container>
      <ThemeProvider theme={currentTheme}>
        <Grid align="stretch">
          <HeaderComponent
            getTranslation={getTranslation}
            onSearchChange={onSearchChange}
            stockStatistics={stockStatistics}
            lang={lang}
          ></HeaderComponent>
          <Table
            articles={articles}
            lang={lang}
            getTranslation={getTranslation}
            sortTableOn={sortTableOn}
          ></Table>
        </Grid>
      </ThemeProvider>
    </Container>
  );
}
