import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Part from "../part/part";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useTheme } from "@mui/styles";

const columns = [
  { id: "arrow", label: "", minWindowWidth: "0" },
  { id: "partNo", label: "partNo", minWindowWidth: "0", sortId: "id" },
  {
    id: "serie",
    label: "Serie",
    minWindowWidth: "0",
    align: "left",
    sortId: "serie",
  },
  {
    id: "description",
    label: "desc",
    minWindowWidth: "0",
    align: "left",
    sortId: "description",
  },
  {
    id: "type",
    label: "QUALITY",
    minWindowWidth: "800",
    align: "left",
    sortId: "text",
  },
  {
    id: "priceInc",
    label: "PRICE_INC",
    minWindowWidth: "0",
    align: "left",
    sortId: "price_se_inc",
    subSortId: "prices",
  },
  {
    id: "priceEx",
    label: "PRICE_EX",
    minWindowWidth: "800",
    align: "left",
    sortId: "price_se_ex",
    subSortId: "prices",
  },
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function TableComponent(props) {
  const { articles, sortTableOn } = props;
  const { lang } = props;
  const { getTranslation } = props;
  const theme = useTheme();
  const [sortDir, setSortDir] = useState(-1);
  const [sortCol, setSortCol] = useState();

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleSortTable = (sortId, sub, sortDir) => {
    setSortCol(sortId);
    setSortDir(-1 * sortDir);
    sortTableOn(sortId, sub, sortDir);
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (

    <Table sx={{ width: '100%', borderColor: "#FF0000" }}>
      <TableHead style={{ backgroundColor: theme.background.main }}>
        <TableRow>
          {columns.map(
            (column) =>
              windowDimensions.width > column.minWindowWidth && (
                <TableCell
                sx={{p: "0 0 0 0"}}
                  key={column.id}
                  align={column.align}
                  style={{ color: theme.text.main , padding:"0px 0px 0px 0px"}}
                  onClick={() =>
                    handleSortTable(column.sortId, column.subSortId, sortDir)
                  }
                >
                   {getTranslation(column.label, lang)}
                  {sortCol && column.sortId === sortCol ? (
                    sortDir === 1 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  ) : (
                    ""
                  )}
                </TableCell>
              )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {articles &&
          articles.length > 0 &&
          articles.map((article) => (
            <Part
              key={article.id}
              article={article}
              windowDimensions={windowDimensions}
              columns={columns}
              lang={lang}
              getTranslation={getTranslation}
            ></Part>
          ))}
      </TableBody>
    </Table>
  );
}
