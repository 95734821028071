import React from "react";
import DetailedPart from "../detailedpart/detailedPart";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@mui/material/Collapse";

import Typography from "@mui/material/Typography";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@mui/styles';



const CustomTableCell = withStyles({
  root: {
    borderBottom: "0px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "0px",
    margin: "0px",
  },
})(TableCell);

function PartComponent(props) {
  const { article, windowDimensions, columns } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { lang } = props;
  const { getTranslation } = props;

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ border: 0 }} style={{backgroundColor: theme.background.part}}>
        <CustomTableCell align="left" style={{ padding: "0px" }}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </CustomTableCell>
        <CustomTableCell align="left">
          <Typography
            sx={{ fontWeight: "bold" }}
            style={{ color: theme.text.part }}
            variant="h7"
            component="div"
          >
            {article.id}
          </Typography>
        </CustomTableCell>
        {windowDimensions.width > columns[2].minWindowWidth && (
          <CustomTableCell align="left">
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
              {article.serie}
            </Typography>
          </CustomTableCell>
        )}
        {windowDimensions.width > columns[3].minWindowWidth && (
          <CustomTableCell align="left">
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
              {article.description} {article.appearance !== 'N/A' ? article.appearance:""}
            </Typography>
          </CustomTableCell>
        )}
        {windowDimensions.width > columns[4].minWindowWidth && (
          <CustomTableCell align="left">
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
              {article.text}
            </Typography>
          </CustomTableCell>
        )}
        {windowDimensions.width > columns[5].minWindowWidth && (
          <CustomTableCell align="left">
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
             {getTranslation("SEK", lang)} {lang === "da" ? article.prices.price_dk_inc.toLocaleString('sv-SE') : article.prices.price_se_inc.toLocaleString('sv-SE')} 
            </Typography>
          </CustomTableCell>
        )}
        {windowDimensions.width > columns[6].minWindowWidth && (
          <CustomTableCell align="left">
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
             {getTranslation("SEK", lang)} {lang === "da" ? article.prices.price_dk_ex.toLocaleString('sv-SE') : article.prices.price_se_ex.toLocaleString('sv-SE')} 
            </Typography>
          </CustomTableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DetailedPart
              part={article}
              windowDimensions={windowDimensions}
              columns={columns}
              lang={lang} 
              getTranslation={getTranslation}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default PartComponent;
