import React from 'react';
import  MainFrame  from './features/mainframe/MainFrame';


function App() {
  return (
    <div className="App">
      <MainFrame />
    </div>
  );
}

export default App;
