import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//import Chip from "@mui/material/Chip";
import { withStyles } from "@material-ui/core/styles";
//import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
//import { matchSorter } from "match-sorter";
//import parse from "autosuggest-highlight/parse";
//import match from "autosuggest-highlight/match";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      background: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const styles = {
  margin: "0 0 0 15px",
  width: "10%",
  "&.MuiButton-root": {
    border: "2px white solid",
  },
  "&.MuiButton-text": {
    color: "white",
  },
  "&.MuiButton-contained": {
    color: "white",
  },
  "&.MuiButton-outlined": {
    color: "white",
  },
};

export default function SearchBar(props) {
  const {getTranslation, lang} = props;
  const [searchWord, setSearchWord] = useState("");
  const theme = useTheme();
  
  /*
  const [inputValue, setInputValue] = React.useState("");
  const [selectColor, setSelectColor] = React.useState([]);
  const { stockStatistics } = props;
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);

  const handleChangeColor = (event) => {
    const {
      target: { value },
    } = event;
    setSelectColor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true);
    }
  };
*/
  const handleTextFieldKeyDown = (event) => {
    if (event && event.key) {
      switch (event.key) {
        case "Enter":
          props.onSearchChange(searchWord);
          break;
        case "Escape":
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box
      style={{ backgroundColor: theme.background.main }}
      sx={{ width: "100%" }}
    >
      <CustomTextField
        id="filled-basic"
        sx={{ width: "60%", m: "0 0 0 15px" }}
        size="small"
        label={getTranslation("MAIN_SEARCH_LABEL", lang)}
        onChange={(event) => setSearchWord(event.target.value)}
        onKeyDown={(event) => handleTextFieldKeyDown(event)}
        InputLabelProps={{
          sx: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "white",
              transform: "translate(0px, -20px) scale(1)",
            },
          },
        }}
      /> 
      {/*stockStatistics &&
        stockStatistics[0] &&
        stockStatistics[0].descriptions && (
          <Autocomplete
            sx={{ width: "60%", m: "0 0 0 15px", minWidth:"280px" }}
            multiple
            readOnly
            open={open}
            onOpen={handleOpen}
            freeSolo
            disableCloseOnSelect
            style={{ backgroundColor: "white" }}
            options={
              stockStatistics &&
              stockStatistics[0] &&
              stockStatistics[0].descriptions
            }
            //getOptionLabel={(option) => option}
            filterOptions={filterOptions}
            onChange={handleChangeColor}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              console.log(value)
              if (reason !== "reset") {
                setInputValue(value);
              }
              if(value.length>0){
                setSearchWord(value);
              }
              console.log(reason)
              handleTextFieldKeyDown(event);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search for part"
                placeholder="Basic"
              />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option, inputValue);
              const parts = parse(option, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
          ></Autocomplete>
          )*/}

      <Button
        variant="outlined"
        sx={styles}
        size="medium"
        onClick={() => props.onSearchChange(searchWord)}
      >
        {getTranslation("MAIN_SEARCH_BUTTON", lang)}
      </Button>
    </Box>
  );
}
