import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import headerStyles from "../headercomponent/headerComponent.module.css";

export default function DetailedPart(props) {
  const { part } = props;
  const { lang } = props;
  const { getTranslation } = props;

  const getQtyInStockText = (m2) => {
    if (m2 < 1) {
      return "SOON";
    }
    if (m2 < 50) {
      return "FEW";
    }
    if (m2 >= 50 && m2 < 100) {
      return "OVER_50";
    }
    if (m2 >= 100) {
      return "OVER_100";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box>
          <a
            style={{ display: "table-cell" }}
            href={
              "https://pricelistimagebanksec.blob.core.windows.net/images/" +
              part.gtin_no +
              ".jpg"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={headerStyles.partImage}
              src={
                "https://pricelistimagebanksec.blob.core.windows.net/images/" +
                part.gtin_no +
                ".jpg"
              }

              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://pricelistimagebanksec.blob.core.windows.net/images/missing_image.png";
              }}
              alt={part.id}
            />
          </a>
        </Box>
      </Grid>
      <Grid item xs={9} >
        <Grid container spacing={2} sx={{ marginTop: "2px" }}>
          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("SERIE_TEXT", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.serie}
              </Typography>
            </Paper>
          </Grid>
          {part.alt_color && part.alt_color.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("ALT_COLOR", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {part.alt_color}
                </Typography>
              </Paper>
            </Grid>
          )}
          {part.color && part.color.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("COLOR", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {part.color}
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={3} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("PRICE_TEXT", lang)}/{getTranslation(part.part_main_group, lang)} inkl moms
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.prices.price_se_inc.toLocaleString("sv-SE")}{" "}
                {getTranslation("SEK", lang)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("PRICE_TEXT", lang)}/{getTranslation(part.part_main_group, lang)} exkl moms
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.prices.price_se_ex.toLocaleString("sv-SE")}{" "}
                {getTranslation("SEK", lang)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("STOCK_STATUS", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.range !== "40" ? getTranslation(getQtyInStockText(part.m2_in_stock.toFixed(0)), lang) : getTranslation("ORDER_ITEM", lang)}
              </Typography>
            </Paper>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("QUALITY", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.text}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                Format
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.dim_quality} mm
              </Typography>
            </Paper>
          </Grid>



          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation(part.part_main_group, lang)}/
                {getTranslation("PACKAGE", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.m2_per_package.toLocaleString("sv-SE")}{" "}
                {getTranslation(part.part_main_group, lang)}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation(part.part_main_group, lang)}/
                {getTranslation("PALL", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {part.m2_per_pallet.toFixed(0)}{" "}
                {getTranslation(part.part_main_group, lang)}
              </Typography>
            </Paper>
          </Grid>



          {part.surface && part.surface !== "NA" && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("SURFACE", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {getTranslation("SURFACE_" + part.surface, lang)}
                </Typography>
              </Paper>
            </Grid>
          )}

          {/*part.trim_piece && part.trim_piece.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("TRIM_PIECE", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {getTranslation("TRIM_" + part.trim_piece, lang)}
                </Typography>
              </Paper>
            </Grid>
          )*/}


          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("PACKAGE_QTY", lang)}
              </Typography>
              <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                {(part.package_qty === 0 ? 1 : part.package_qty)}
              </Typography>
            </Paper>
          </Grid>


          {part.appearance && part.appearance.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("APPEARANCE", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {part.appearance}
                </Typography>
              </Paper>
            </Grid>
          )}


        </Grid>
      </Grid>
    </Grid>
  );
}
